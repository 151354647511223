import { fullStack } from "make-error-cause";
import { BaseError } from "make-error";
import { err, Result, ResultAsync } from "neverthrow";
import { getProperty } from "~/units/typescriptHelpers";
import { logLevels } from "~/logging/levels";
import { NotFoundError } from "~/errors/classes";
import type { Logger } from "~/logging/Logger";

/**
 * Returns a string with the error message and all its preceding causes.
 *
 * This is similar to `fullStack` from the `make-error-cause` package,
 * but it works for messages instead of stack traces.
 *
 * @param error
 *   An error. This is typed as `unknown` because in Javascript, we may unfortunately throw anything!
 */
export function getErrorMessagesWithCauses(error: unknown): string {
  function eToString(e: unknown): string {
    const name = getProperty(e, "name");
    const message = getProperty(e, "message");

    // BaseError and Error usually have a name and a message.
    if (name && message) return `${name}: ${message}`;

    if (message) return String(message);

    return String(e);
  }

  let result = eToString(error);

  let cause = getProperty(error, "cause");
  while (cause) {
    result += `\nCaused by: ${eToString(cause)}`;
    cause = getProperty(cause, "cause");
  }

  return result;
}

/**
 * Serializable error information.
 *
 * Safe to use in places where it might be sent from server to client, like in `useQuery`.
 */
export interface SerializableErrorInfo {
  /**
   * The error name and message, and that of all preceding causes.
   */
  message: string;

  /**
   * The full stack trace, including that of all preceding causes.
   */
  stack?: string;

  /**
   * The HTTP response code, if we could find one in the error or its causes.
   */
  statusCode?: number;
}

/**
 * Find an HTTP status code in the given error or one of its causes.
 *
 * @param e
 *   An error. This is typed as `unknown` because in Javascript, we may unfortunately throw anything!
 */
export function findHttpStatusCode(e: unknown): number | undefined {
  const response = getProperty(e, "response");
  const status = getProperty(response, "status");
  const num = status ? Number(status) || undefined : undefined;
  if (num) {
    return num;
  }
  const cause = getProperty(e, "cause");
  if (cause) {
    return findHttpStatusCode(cause);
  }
  return undefined;
}

/**
 * Process an `Error` into a `SerializableErrorInfo` object, which is safe to use
 * in places where it might be sent from server to client, like in `useQuery`.
 *
 * @param e
 *   An error. This is typed as `unknown` because in Javascript, we may unfortunately throw anything!
 */
export function serializeError(e: unknown): SerializableErrorInfo {
  return {
    message: getErrorMessagesWithCauses(e),
    statusCode: findHttpStatusCode(e),
    stack:
      e instanceof Error || e instanceof BaseError ? fullStack(e) : undefined,
  };
}

/**
 * Turn an unknown value into an Error object.
 */
export function castToError(e: unknown): Error {
  return e instanceof Error ? e : new Error(String(e));
}

export type SerializableResult<T> =
  | { value: T; error: undefined }
  | { value: undefined; error: SerializableErrorInfo };

/**
 * Execute the getter while handling server side errors and making them serializable.
 *
 * `T` is assumed to already be serializable.
 *
 * @param getter A function that returns a `ResultAsync` object.
 * @param logServerSideError A callback to log errors when running on the server.
 *
 * @returns A promise that resolves to a serializable version of the `Result` object. The Promise will never reject.
 */
export async function makeResultAsyncSerializable<T>(
  getter: () => ResultAsync<T, Error>,
  logServerSideError: (e: Error, s: SerializableErrorInfo) => void,
): Promise<SerializableResult<T>> {
  let result: Result<T, Error>;

  try {
    result = await getter();
  } catch (e) {
    // The `getter` should not throw, but if it does, we can recover by continuing as if the error was returned
    // as part of the `Result` object.
    result = err(castToError(e));
  }

  // Here we may only return serializable things.
  // `ResultAsync` and `Result` are not serializable, so we must use plain objects.
  // Assume that `T` is serializable.
  if (result.isOk()) {
    return { value: result.value, error: undefined };
  }
  return {
    value: undefined,
    error: serializeErrorAndLogServerSideAndRemoveStackTrace(
      result.error,
      logServerSideError,
    ),
  };
}

/**
 * Turn an error into a serializable object.
 * If running on the server, log it and remove the stack trace.
 *
 * @param error The error to serialize.
 * @param logServerSideError A callback to log errors when running on the server.
 */
function serializeErrorAndLogServerSideAndRemoveStackTrace<E extends Error>(
  error: E,
  logServerSideError: (e: E, s: SerializableErrorInfo) => void,
): SerializableErrorInfo {
  // Turn the error into a serializable object.
  const serialized = serializeError(error);

  if (process.server) {
    // It's not safe to send the full stack trace from the server to the client, but we can send it directly to Sentry.
    logServerSideError(error, { ...serialized });

    // Exclude the stack trace from what we send to the client.
    serialized.stack = undefined;
  }

  return serialized;
}

export function logSsrErrorToSentry(
  logger: Logger,
  e: Error,
  s: SerializableErrorInfo,
): void {
  if (shouldLogErrorToSentry(e)) {
    logger.log(
      logLevels.error,
      `Error during SSR: ${e.message}`,
      e,
      { ...s },
      false,
    );
  }
}

function shouldLogErrorToSentry(e: Error): boolean {
  // Be very specific with what we omit, and log everything else!
  return !(e instanceof NotFoundError);
}
